import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TripleImage from '../components/tripleimage';
import { Link } from "gatsby";

function LostSomeone() {
  return (
    <Layout>
      <SEO 
      title="Support Someone"
      description="For those who would like to support someone who has lost a family member or friend to suicide."
       />
      <div>
        <div className="container mx-auto px-2 md:py-24">
        <Link to="/"><div className="font-bold uppercase text-xs underline mb-4">Back home</div></Link>
          <div className="flex flex-col md:flex-row items-center md:-mx-2">
            <div className="w-full md:w-4/12 px-2">
              <h2 className="text-3xl py-2 lg:pr-24 leading-tight">I'd like to support someone who has lost a family member or friend to suicide. How?</h2>
              <p className="text-primary py-2 lg:pr-12 leading-relaxed">Suicide can have a devastating and unexpected impact on the family, friends and connections of the person who died.</p>
              <p className="text-primary py-2 lg:pr-12 leading-relaxed">A person's beliefs and experiences can influence how they feel, what they say and how they say it after losing someone they care about to suicide. Some people may feel shame and not want to connect with friends or reach out for support. Others may be focused on finalising practical matters to help them through this time. Every person's response will be different.</p>
              <p className="text-primary py-2 lg:pr-12 leading-relaxed">However, the support of family and friends can make a major difference in how they cope with their bereavement. If you are wanting to support someone who has lost someone they care about to suicide, there is help available including information to read, people to speak to and professional services that can assist. Read on to learn more.</p>

            </div>
            <div className="w-full md:w-8/12 px-2 order-first md:order-none">
            <div className="p-4">
              <TripleImage 
                image1="6.jpg"
                image2="1.jpg"
                image3="3.jpg"
                mode={3}
               />
               </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container px-2  mx-auto py-12">
          <div className="flex flex-col md:flex-row md:-mx-2">
            <div className="w-full md:w-4/12 px-2">
            <div className="bg-box1 h-full p-6 rounded">
              <h3 className="text-2xl py-2 font-bold">Something to read</h3>
              <p className="text-primary py-2 leading-relaxed">For information on the do's and don'ts of what to say and ways to offer practical and emotional help to someone bereaved by suicide, <a target="_blank" rel="noopener noreferrer" href="https://www.beyondblue.org.au/the-facts/suicide-prevention/after-a-suicide-loss/supporting-someone-after-a-suicide-loss" className="underline">click&nbsp;here.</a></p>
              <p className="text-primary py-2 leading-relaxed">For general tips on talking to someone who has lost someone they care about to suicide, <a target="_blank" rel="noopener noreferrer" href="http://www.conversationsmatter.com.au/resources-community/those-bereaved-by-suicide" className="underline">click&nbsp;here.</a></p>
              <p className="text-primary py-2 leading-relaxed"> Supporting someone else in their grief can be overwhelming and exhausting. Taking care of yourself is just as important as taking care of the person affected by suicide. <a target="_blank" rel="noopener noreferrer" href="https://www.beyondblue.org.au/the-facts/suicide-prevention/worried-about-someone-suicidal/self-care-for-the-supporter" className="underline">Click&nbsp;here</a> for information on how to look after yourself during this time.</p>
            </div>
            </div>
            <div className="w-full md:w-4/12 px-2">
            <div className="bg-box2 h-full p-6 rounded">
              <h3 className="text-2xl py-2 font-bold">Speak to someone</h3>
              <p className="text-primary py-2 leading-relaxed">A helpline can help to guide you in how to support someone bereaved by suicide and how to look after yourself during this time. A list of helplines you can contact is <a target="_blank" rel="noopener noreferrer" href="https://www.mymentalhealth.org.au/page/services/community-services/sp-care-pathways/self-guided-support/helplines/" className="underline">available here.</a></p>
              <p className="text-primary py-2 leading-relaxed">You can also encourage the person you are supporting to visit a GP. A GP can make a diagnosis, discuss suitable treatment options and refer them to a mental health professional if needed. You can search for a closely located General Practice by  <a target="_blank" rel="noopener noreferrer" href="https://www.mymentalhealth.org.au/page/services/general-practice-and-allied-health/general-practice/" className="underline">clicking&nbsp;here.</a></p>
            </div>
            </div>
            <div className="w-full md:w-4/12 px-2">
            <div className="bg-box3 h-full p-6 rounded">
              <h3 className="text-2xl py-2 font-bold">Access professional help</h3>
              <p className="text-primary py-2 leading-relaxed">A range of mental health services are available in the Brisbane North region.</p>
              <p className="text-primary py-2 leading-relaxed">If you need help supporting someone bereaved by suicide or are worried the person you are supporting is in need of professional help,  the My Mental Health Service Navigators can assist by linking you both to professional services. </p>
              <p className="text-primary py-2 leading-relaxed">Call <a className="whitespace-no-wrap underline" href="tel:1800752235">1800 752 235</a>. </p>
              <p className="text-primary py-2 leading-relaxed">Please note, this is not a crisis service and operates 8.30 am - 4.30 pm, Monday to Friday.</p>
            </div>
            </div>
          </div>
        </div>
      </div>
      
    </Layout>
  );
}

export default LostSomeone;
